import { FC, ReactNode, useState } from 'react';
import { Modal } from './Modal';
import { Button } from './Button';
import { Link } from './Link';

interface ApplyButtonProps {
  className?: string;
  primaryColor?: string;
  secondaryColor?: string;
  href?: string;
  unavailable?: boolean;
  unavailableContent?: ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ApplyButton: FC<ApplyButtonProps> = ({
  className,
  primaryColor,
  secondaryColor,
  href,
  unavailable,
  unavailableContent,
  onClick,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={className}>
      {!unavailable && (
        <Link href={href ?? ''} targetBlank>
          <Button primaryColor={primaryColor} secondaryColor={secondaryColor} onClick={onClick}>
            Δηλωστε συμμετοχη
          </Button>
        </Link>
      )}
      {unavailable && (
        <>
          <Button primaryColor={primaryColor} secondaryColor={secondaryColor} onClick={() => setVisible(true)}>
            Δηλωστε συμμετοχη
          </Button>
          <Modal visible={visible} onClose={() => setVisible(false)}>
            {unavailableContent}
          </Modal>
        </>
      )}
    </div>
  );
};
