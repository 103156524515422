import styled from 'styled-components';
import { media } from 'style';
import { Wrapper } from './Wrapper';

export default {
  Header: styled.header`
    display: flex;
    justify-content: center;
    background: linear-gradient(135deg, #ac2fde 0%, #5235c9 100%);
    color: white;
  `,
  Wrapper: styled(Wrapper)`
    padding: 100px 20px;

    ${media.sm`
      padding: 150px 20px;  
    `}
  `,
};
