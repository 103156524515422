import { FC } from 'react';
import { GatsbyLinkProps } from 'gatsby';
import { font } from 'style';

import S from './InnerLink.Styled';

interface InnerLinkProps {
  className?: string;
  to: GatsbyLinkProps<void>['to'];
  fontSize?: string;
  fontWeight?: number;
  uppercase?: boolean;
}

export const InnerLink: FC<InnerLinkProps> = ({
  className,
  to,
  children,
  fontSize = font.size.normal,
  fontWeight = font.weight.normal,
  uppercase = false,
}) => {
  return (
    <S.InnerLink className={className} to={to} fontSize={fontSize} fontWeight={fontWeight} $uppercase={uppercase}>
      {children}
    </S.InnerLink>
  );
};
