import { FC } from 'react';

import S from './Link.Styled';

interface LinkProps {
  className?: string;
  href: string;
  targetBlank?: boolean;
}

export const Link: FC<LinkProps> = ({ className, href, targetBlank, children }) => {
  return (
    <S.Link
      className={className}
      href={href}
      target={targetBlank ? '_blank' : undefined}
      rel={targetBlank ? 'noreferrer noopener' : undefined}
    >
      {children}
    </S.Link>
  );
};
