import { FC, ReactNode } from 'react';
import bulletIcon from 'assets/images/tick.svg';
import S from './Bullets.Styled';

interface BulletItemProps {
  text: ReactNode;
  number?: number;
  showOrder?: boolean;
}

export const BulletItem: FC<BulletItemProps> = ({ text, number, showOrder }) => (
  <S.BulletItem>
    {!showOrder && <S.BulletIcon src={bulletIcon} />}
    {showOrder && <S.OrderIndicator>{number}</S.OrderIndicator>}
    <S.BulletText>{text}</S.BulletText>
  </S.BulletItem>
);

interface BulletsProps {
  bullets: ReactNode[];
  showOrder?: boolean;
}

export const Bullets: FC<BulletsProps> = ({ bullets, showOrder }) => {
  return (
    <S.BulletsListContainer>
      <S.BulletList>
        {bullets?.map((bullet, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <BulletItem key={index} text={bullet} number={index + 1} showOrder={showOrder} />
        ))}
      </S.BulletList>
    </S.BulletsListContainer>
  );
};
