import styled from 'styled-components';
import { media } from 'style';

export default {
  Wrapper: styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 60px 20px;

    ${media.sm`
        padding: 80px 20px;
    `}
  `,
};
