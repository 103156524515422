import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { createGlobalStyle } from 'styled-components';
import { font, color } from 'style';
import mainMeta from 'assets/images/main-meta.jpg';
import favicon from 'assets/images/favicon.png';
import { Menu, MenuItemProps } from './Menu';
import 'assets/css/layout.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-family: ${font.family.roboto};
    font-size: ${font.size.normal};
    font-weight: ${font.weight.light};
    color: ${color.hmBlack};

    .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
      opacity: 1;
    }

    .ReactModal__Overlay--before-close{
      opacity: 0;
    }
  }
`;

interface MetaData {
  title?: string;
  description?: string;
  image?: string;
}

interface LayoutProps {
  meta?: MetaData;
  logo?: string;
  menuItems?: MenuItemProps[];
}

const constTitle = 'Project Equall';

const defaultMetaData = {
  title: undefined,
  description: 'Project Equall',
  image: mainMeta,
};

export const Layout: FC<LayoutProps> = ({ children, meta = {}, logo, menuItems }) => {
  const metaData = { ...defaultMetaData, ...meta };
  return (
    <>
      <Helmet
        title={metaData.title}
        defaultTitle="Project Equall"
        titleTemplate={`%s | ${constTitle}`}
        meta={[
          {
            name: `description`,
            content: metaData.description,
          },
          {
            property: `og:title`,
            content: metaData.title,
          },
          {
            property: `og:description`,
            content: metaData.description,
          },
          {
            property: `og:image`,
            content: metaData.image,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: '',
          },
          {
            name: `twitter:title`,
            content: metaData.title,
          },
          {
            name: `twitter:description`,
            content: metaData.description,
          },
          {
            name: 'facebook-domain-verification',
            content: 'k433pojz80flzy6idz5oatybetzpue',
          },
        ]}
        defer={false}
      >
        <link rel={favicon} type="image/ico" href={favicon} />
      </Helmet>

      <GlobalStyle />
      <Menu logo={logo} menuItems={menuItems} />
      {children}
    </>
  );
};
