export const color = {
  white: '#ffffff',
  hmBrandPurple: '#794c91',
  hmBlack: '#3e3e3e',
  hmBlue: '#068deb',
  hmGreen: '#60cb92',
  hmPurple1: '#FCFBFD',
  hmPurple2: '#FBF8FF',
  hmPurple3: '#CAC1D3',
  hmPurple4: '#AFA2FF',
  hmGrey1: '#e8e8e8',
  hmGrey3: '#898989',
  paleGrey: '#fcfbfd',
};
