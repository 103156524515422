import { FC } from 'react';
import PiraeusBankLogo from 'assets/images/brand-logo-piraeus-bank.svg';
import HmLogo from 'assets/images/brand-logo-100mentors.svg';
import TTPLogo from 'assets/images/brand-logo-ttp.svg';
import DereeLogo from 'assets/images/brand-logo-deree.png';
import AfstLogo from 'assets/images/brand-logo-afst.png';
import { Wrapper } from './Wrapper';

import S from './Footer.Styled';

interface LogoProps {
  image: string;
  alt: string;
  size?: number;
}

type LogoNames = 'piraeus' | '100mentors' | 'ttp' | 'deree' | 'afst';

const logoItems: Record<LogoNames, LogoProps> = {
  piraeus: {
    image: PiraeusBankLogo,
    alt: 'Τράπεζα Πειραιώς Λογότυπο',
    size: 150,
  },
  '100mentors': {
    image: HmLogo,
    alt: '100mentors Λογότυπο',
    size: 230,
  },
  ttp: {
    image: TTPLogo,
    alt: 'The Tipping Point Λογότυπο',
    size: 50,
  },
  deree: {
    image: DereeLogo,
    alt: 'Deree Λογότυπο',
    size: 60,
  },
  afst: {
    image: AfstLogo,
    alt: 'Αμερικανική Γεωργική Σχολή Θεσσαλονίκης Λογότυπο',
    size: 200,
  },
};

interface SignatureSection {
  title: string;
  logos?: LogoNames[];
  direction?: 'horizontal' | 'vertical';
}

interface FooterProps {
  backgroundColor?: string;
  signatures?: SignatureSection[];
  logos?: LogoNames[];
}

export const Footer: FC<FooterProps> = ({
  backgroundColor = 'white',
  logos = ['piraeus', '100mentors', 'ttp'],
  signatures,
}) => {
  return (
    <S.Footer backgroundColor={backgroundColor}>
      <Wrapper>
        <S.Container>
          {signatures?.map((signature) => (
            <S.SignatureContainer key={signature.title}>
              <S.Title>{signature.title}</S.Title>
              <S.LogoContainer direction={signature.direction}>
                {signature.logos?.map((logoKey) => (
                  <S.Logo
                    key={logoKey}
                    src={logoItems[logoKey].image}
                    alt={logoItems[logoKey].alt}
                    size={logoItems[logoKey]?.size}
                  />
                ))}
              </S.LogoContainer>
            </S.SignatureContainer>
          ))}
          {!signatures && logos && (
            <S.SignatureContainer>
              <S.Title>Στρατηγικοί Συνεργάτες</S.Title>
              <S.LogoContainer>
                {logos.map((logoKey) => (
                  <S.Logo key={logoKey} src={logoItems[logoKey].image} alt={logoItems[logoKey].alt} />
                ))}
              </S.LogoContainer>
            </S.SignatureContainer>
          )}
        </S.Container>
      </Wrapper>
    </S.Footer>
  );
};
