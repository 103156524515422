import React, { FC } from 'react';
import { color } from 'style';
import S from './Button.Styled';

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  primaryColor?: string;
  secondaryColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Button: FC<ButtonProps> = ({
  className,
  children,
  disabled,
  primaryColor = color.white,
  secondaryColor = color.hmBrandPurple,
  onClick,
}) => {
  return (
    <S.Button
      className={className}
      disabled={disabled}
      backgroundColor={primaryColor}
      textColor={secondaryColor}
      onClick={onClick}
    >
      {children}
    </S.Button>
  );
};
