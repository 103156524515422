import { FC } from 'react';

import S from './Wrapper.Styled';

interface WrapperProps {
  className?: string;
}

export const Wrapper: FC<WrapperProps> = ({ className, children }) => {
  return <S.Wrapper className={className}>{children}</S.Wrapper>;
};
