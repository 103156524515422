export const font = {
  family: {
    lato: 'Lato',
    roboto: 'Roboto',
  },
  size: {
    small: '12px',
    normal: '16px',
    large: '18px',
    xLarge: '22px',
    xxLarge: '32px',
    xxxLarge: '38px',
  },
  weight: {
    light: 300,
    normal: 400,
    bold: 700,
  },
};
