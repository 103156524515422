import styled from 'styled-components';
import { darken } from 'polished';
import { animated } from 'react-spring';
import { media, color, font } from 'style';
import { Link } from './Link';
import { InnerLink } from './InnerLink';

export default {
  Menu: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  `,
  DesktopMenu: styled.div`
    display: none;

    ${media.md`
      display:flex;
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px 30px;
    `}
  `,
  MobileMenu: styled(animated.div)`
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.md`
      display:none;
    `};
  `,
  MobileMenuHead: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  `,
  Logo: styled.div`
    padding: 20px;
  `,
  MenuItem: styled.div`
    &:not(:last-child) {
      margin-right: 30px;
    }
  `,
  MenuItems: styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
  `,
  MobileMenuItems: styled(animated.div)`
    display: flex;
    flex-direction: column;
  `,
  Link: styled(Link)`
    color: #fff;

    &:hover {
      color: ${darken(0.1, '#fff')};
    }

    transition: color 0.2s ease;
  `,
  BurgerButton: styled.button`
    padding: 20px;
    &:focus {
      outline: none;
    }
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: ${color.white} !important;
    }
  `,
  MobileMenuItem: styled.div`
    margin: 0px 40px;
    box-sizing: border-box;

    &:not(:last-child) {
      border-bottom: 1px solid ${color.white};
    }
  `,
  MobileMenuItemInnerLink: styled(InnerLink)`
    font-size: 17px !important;
    height: 44px;
    display: flex;
    align-items: center;
    color: ${color.white};
    font-weight: ${font.weight.normal};

    :hover {
      color: ${color.white};
    }
  `,
  MobileMenuItemLink: styled(Link)`
    font-size: 17px !important;
    height: 44px;
    display: flex;
    align-items: center;
    color: ${color.white};
    font-weight: ${font.weight.normal};

    :hover {
      color: ${color.white};
    }
  `,
  LogoImage: styled.img`
    width: 200px;
  `,
};
