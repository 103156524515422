import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { color } from 'style';

export default {
  InnerLink: styled(Link)<{ fontSize: string; fontWeight: number; $uppercase: boolean }>`
    color: ${color.hmBlue};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    ${({ $uppercase }) =>
      $uppercase &&
      css`
        text-transform: uppercase;
      `};
    text-decoration: none;
  `,
};
