import { css } from 'styled-components';
import { breakpoints } from './breakpoints';

function resCss(input: TemplateStringsArray, breakpoint: string) {
  return css`
    @media only screen and (min-width: ${breakpoint}) {
      ${input};
    }
  `;
}

function xs(input: TemplateStringsArray) {
  return resCss(input, breakpoints.xs.min);
}

function sm(input: TemplateStringsArray) {
  return resCss(input, breakpoints.sm.min);
}

function md(input: TemplateStringsArray) {
  return resCss(input, breakpoints.md.min);
}

function lg(input: TemplateStringsArray) {
  return resCss(input, breakpoints.lg.min);
}

function xl(input: TemplateStringsArray) {
  return resCss(input, breakpoints.xl.min);
}

export const media = {
  xs,
  sm,
  md,
  lg,
  xl,
};
