import { FC, ReactNode, useState } from 'react';
import { useSpring } from 'react-spring';
import { createGlobalStyle } from 'styled-components';
import _ from 'lodash';
import { color } from 'style';
import { InnerLink } from './InnerLink';
import { Button } from './Button';
import { ApplyButton } from './ApplyButton';
import { Link } from './Link';
import S from './Menu.Styled';
import 'assets/css/hamburgers.min.css';

export enum MenuItemTypeEnum {
  LINK,
  LINK_BUTTON,
  BUTTON,
  APPLY_BUTTON,
}

interface MenuItemLink {
  label: string;
  href: string;
  type: MenuItemTypeEnum.LINK;
}

interface MenuItemButton {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  type: MenuItemTypeEnum.BUTTON;
}

interface MenuItemLinkButton {
  label: string;
  href: string;
  type: MenuItemTypeEnum.LINK_BUTTON;
}

interface MenuItemApplyButton {
  href?: string;
  unavailable?: boolean;
  unavailableContent?: ReactNode;
  type: MenuItemTypeEnum.APPLY_BUTTON;
  onApply: () => void;
}

export type MenuItemProps = MenuItemLink | MenuItemButton | MenuItemLinkButton | MenuItemApplyButton;

interface MenuProps {
  logo?: string;
  menuItems?: MenuItemProps[];
}

const MenuItem: FC<MenuItemProps> = (props) => {
  const { type } = props;
  switch (type) {
    case MenuItemTypeEnum.LINK: {
      const { href, label } = props;
      return (
        <S.MenuItem>
          <S.Link href={href} targetBlank>
            {label}
          </S.Link>
        </S.MenuItem>
      );
    }
    case MenuItemTypeEnum.LINK_BUTTON: {
      const { href, label } = props;
      return (
        <S.MenuItem>
          <Link href={href} targetBlank>
            <Button>{label}</Button>
          </Link>
        </S.MenuItem>
      );
    }
    case MenuItemTypeEnum.BUTTON: {
      const { onClick, label } = props;
      return (
        <S.MenuItem>
          <Button onClick={onClick}>{label}</Button>
        </S.MenuItem>
      );
    }
    case MenuItemTypeEnum.APPLY_BUTTON: {
      const { href, unavailable, unavailableContent, onApply } = props;
      return (
        <S.MenuItem>
          <ApplyButton
            href={href}
            unavailable={unavailable}
            unavailableContent={unavailableContent}
            onClick={onApply}
          />
        </S.MenuItem>
      );
    }
    default:
      return null;
  }
};

const isMenuItemLink = (menuItem: MenuItemProps): menuItem is MenuItemLink | MenuItemApplyButton => {
  return [MenuItemTypeEnum.LINK, MenuItemTypeEnum.LINK_BUTTON].includes(menuItem.type);
};

const MobileGlobalStyle = createGlobalStyle<{ mobileMenuClose: boolean }>`
  body {
    height: 100%;
    overflow: ${({ mobileMenuClose }) => (mobileMenuClose ? 'visible' : 'hidden')}
  }
`;

export const Menu: FC<MenuProps> = ({ logo, menuItems }) => {
  const [mobileMenuClose, setMobileMenuClose] = useState(true);

  const navBarAnimation = useSpring({
    height: mobileMenuClose ? '0vh' : '100vh',
    background: mobileMenuClose ? `${color.white}` : `#ac2fde`,
  });

  const itemAnimation = useSpring({
    transform: mobileMenuClose ? 'translateX(-100%)' : 'translateX(0%)',
    opacity: mobileMenuClose ? 0 : 1,
  });

  const menuButtonSwitch = () => {
    if (!mobileMenuClose && window) {
      window.scrollTo(0, 0);
    }
    setMobileMenuClose(!mobileMenuClose);
  };

  return (
    <S.Menu>
      <S.DesktopMenu>
        <S.Logo>
          {logo && (
            <InnerLink to="/">
              <S.LogoImage src={logo} alt="Equall Λογότυπο" />
            </InnerLink>
          )}
        </S.Logo>
        <S.MenuItems>
          {menuItems?.map((item) => (
            <MenuItem key={'label' in item ? item.label : item.href} {...item} />
          ))}
        </S.MenuItems>
      </S.DesktopMenu>
      <S.MobileMenu style={navBarAnimation}>
        <S.MobileMenuHead>
          <S.Logo>
            {logo && (
              <InnerLink to="/">
                <img src={logo} alt="logo" />
              </InnerLink>
            )}
          </S.Logo>
          {!_.isEmpty(menuItems) && (
            <S.BurgerButton
              className={`hamburger hamburger--collapse ${!mobileMenuClose && 'is-active'}`}
              type="button"
              onClick={menuButtonSwitch}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </S.BurgerButton>
          )}
        </S.MobileMenuHead>
        <S.MobileMenuItems style={itemAnimation}>
          <S.MobileMenuItem>
            <S.MobileMenuItemInnerLink to="/">Αρχική</S.MobileMenuItemInnerLink>
          </S.MobileMenuItem>
          {menuItems?.map(
            (item: MenuItemLink) =>
              isMenuItemLink(item) && (
                <S.MobileMenuItem key={item.label}>
                  <S.MobileMenuItemLink href={item.href}>{item.label}</S.MobileMenuItemLink>
                </S.MobileMenuItem>
              ),
          )}
        </S.MobileMenuItems>
      </S.MobileMenu>
      <MobileGlobalStyle mobileMenuClose={mobileMenuClose} />
    </S.Menu>
  );
};
