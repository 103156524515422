import styled from 'styled-components';
import { media, color, font } from 'style';

export default {
  BulletsListContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
  BulletList: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;

    ${media.md`
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    `}
  `,
  BulletItem: styled.div`
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    align-items: center;
    max-width: 400px;

    ${media.sm`
      flex-basis: 50%;
    `}
  `,
  BulletIcon: styled.img`
    margin-right: 10px;
    width: 25px;
  `,
  BulletText: styled.div`
    max-width: 420px;
    ${media.md`
      max-width: 360px;
    `}
    ${media.lg`
      max-width: 420px;
    `}
  `,
  OrderIndicator: styled.div`
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    color: ${color.white};
    background-color: ${color.hmPurple4};
    line-height: 50px;
    text-align: center;
    margin-right: 20px;
    font-size: ${font.size.xxLarge};
  `,
};
