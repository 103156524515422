import styled from 'styled-components';
import { darken } from 'polished';
import { font } from 'style';

export default {
  Button: styled.button<{ backgroundColor: string; textColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ textColor }) => textColor};
    font-weight: ${font.weight.bold};
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1;
    padding: 15px;
    border-radius: 2px;
    border: transparent;
    cursor: pointer;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: ${({ backgroundColor }) => darken(0.1, backgroundColor)};
    }

    transition: background-color 0.2s ease;
  `,
};
