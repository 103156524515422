import styled, { css } from 'styled-components';
import { media } from 'style';
import { Heading } from './Heading';

export default {
  Footer: styled.footer<{ backgroundColor: string }>`
    display: flex;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 100px;
  `,
  Title: styled(Heading)`
    text-align: center;
  `,
  SignatureContainer: styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  `,
  LogoContainer: styled.div<{ direction?: 'horizontal' | 'vertical' }>`
    flex-grow: 1;
    display: flex;
    flex-flow: ${({ direction = 'horizontal' }) => (direction === 'horizontal' ? 'row' : 'column')} wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 40px;
    padding-top: 25px;
    height: 100%;

    ${media.md`
      flex-wrap: no-wrap;
    `}
  `,
  Logo: styled.img<{ size?: number }>`
    ${({ size }) => css`
      width: ${size}px;
    `}

    ${media.sm`
      /* margin: 50px 25px; */
    `}
  `,
};
