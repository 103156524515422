import styled, { css } from 'styled-components';
import { media } from 'style';

// Development Helper, view breakpoints
export const ResponsiveRuler = styled.div<{ position: 'bottom' | 'top' }>`
  position: fixed;
  ${({ position }) =>
    position === 'bottom'
      ? css`
          bottom: 0px;
        `
      : css`
          top: 0px;
        `}
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: white;
  background-color: Crimson;
  &::after {
    padding-left: 10px;
    content: 'Mobile';
  }

  ${media.sm`
    background-color: DarkOliveGreen;
    &::after {
      padding-left: 10px;
      content: 'Tablet Small';
    }
  `}
  ${media.md`
    background-color: DarkGreen;
    &::after {
      padding-left: 10px;
      content: 'Tablet Large';
    }
  `}
  ${media.lg`
    background-color: DarkCyan;
    &::after {
      padding-left: 10px;
      content: 'Desktop Small';
    }
  `}
  ${media.xl`
    background-color: DarkBlue;
    &::after {
      padding-left: 10px;
      content: 'Desktop Large';
    }
  `}
`;
