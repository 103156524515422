import styled, { css } from 'styled-components';

export default {
  Heading: styled.div<{ color: string; fontFamily: string; fontSize: string; fontWeight: number; $uppercase: boolean }>`
    color: ${({ color }) => color};
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    ${({ $uppercase }) =>
      $uppercase &&
      css`
        text-transform: uppercase;
      `};
  `,
};
