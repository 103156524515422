import styled from 'styled-components';
import { color } from 'style';

export default {
  Link: styled.a`
    color: ${color.hmBlue};
    text-decoration: none;
    word-break: break-word;
  `,
};
