import { FC } from 'react';

import S from './Header.Styled';

interface HeaderProps {
  className?: string;
}

export const Header: FC<HeaderProps> = ({ className, children }) => {
  return (
    <S.Header className={className}>
      <S.Wrapper>{children}</S.Wrapper>
    </S.Header>
  );
};
