import { FC } from 'react';
import { font, color as themeColor } from 'style';

import S from './Heading.Styled';

interface HeadingProps {
  className?: string;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: number;
  uppercase?: boolean;
}

export const Heading: FC<HeadingProps> = ({
  className,
  children,
  color = themeColor.hmBlack,
  fontFamily = font.family.roboto,
  fontSize = font.size.xxLarge,
  fontWeight = font.weight.normal,
  uppercase = false,
}) => {
  return (
    <S.Heading
      className={className}
      color={color}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      $uppercase={uppercase}
    >
      {children}
    </S.Heading>
  );
};
