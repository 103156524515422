import { FC } from 'react';
import { Wrapper } from './Wrapper';

import S from './Section.Styled';

interface SectionProps {
  backgroundColor?: string;
  className?: string;
}

export const Section: FC<SectionProps> = ({ children, backgroundColor = 'white', className }) => {
  return (
    <S.Section backgroundColor={backgroundColor} className={className}>
      <Wrapper>{children}</Wrapper>
    </S.Section>
  );
};
