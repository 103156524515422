/* Tailwind CSS 3.0 breakpoints */
export const breakpoints = {
  xs: {
    /* Mobile Portrait - Landscape */
    min: '0px',
    max: '639px',
  },
  sm: {
    /* Tablet Small Or Mobile Landscape */
    min: '640px',
    max: '767px',
  },
  md: {
    /* Tablet Large */
    min: '768px',
    max: '1023px',
  },
  lg: {
    /* Desktop - Small */
    min: '1024px',
    max: '1279px',
  },
  xl: {
    /* Desktop - Large */
    min: '1279px',
    max: '9999px',
  },
};
