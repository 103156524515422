import styled from 'styled-components';
import ReactModal from 'react-modal';

export default {
  Modal: styled(ReactModal)`
    position: absolute;
    top: 30%;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 10px;
    background-color: white;
    border-radius: 3px;

    @media only screen and (min-width: 620px) {
      margin: 0 auto;
    }
  `,
  Content: styled.div`
    padding: 70px 25px;
  `,
  CloseButton: styled.button`
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 15px;
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
  `,
};
