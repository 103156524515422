import { FC } from 'react';
import ReactModal from 'react-modal';
import closeIcon from 'assets/images/close.svg';

import S from './Modal.Styled';

ReactModal.setAppElement('#___gatsby');

interface ModalProps {
  visible?: boolean;
  onClose?: () => void;
}

export const Modal: FC<ModalProps> = ({ visible = false, children, onClose }) => {
  return (
    <S.Modal
      isOpen={visible}
      onRequestClose={onClose}
      closeTimeoutMS={200}
      style={{ overlay: { backgroundColor: 'rgb(0, 0, 0, 0.45)' } }}
    >
      <S.Content>
        <S.CloseButton onClick={onClose}>
          <img src={closeIcon} alt="Κλείσιμο" />
        </S.CloseButton>
        {children}
      </S.Content>
    </S.Modal>
  );
};
